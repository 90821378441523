
import Vue from 'vue';
import { AccountType, ConnectedAd, GoogleAd, GoogleUser, RawGoogleAd } from '../../../../store/modules/googleAds/types';
import { RefreshToSeeUpdatedDate } from '../constants';

interface Data {
  users: GoogleUser[];
  search: string;
  loading: boolean;
  updating: boolean;
  changed: boolean;
  selectedAds: GoogleAd[];
  rawAds: RawGoogleAd[];
  RefreshToSeeUpdatedDate: string;
}

export default Vue.extend({
  name: 'GoogleAds',
  props: {
    advertiser: {
      type: Object,
      required: true,
    },
    editRights: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator(v: AccountType) {
        return [AccountType.SEM, AccountType.GOOGLEVIDEO].includes(v);
      },
    },
  },

  data: (): Data => ({
    search: '',
    users: [],
    loading: false,
    updating: false,
    changed: false,
    selectedAds: [],
    rawAds: [],
    RefreshToSeeUpdatedDate,
  }),

  created() {
    this.loading = true;
    Promise.all([this.getAvailableAds(), this.getConnectedAds()]).finally(() => {
      this.loading = false;
    });
  },

  computed: {
    error(): boolean {
      return this.$store.state.googleAds.error;
    },
    title() {
      const titles = new Map([
        [AccountType.SEM, 'Google SEM Ads'],
        [AccountType.GOOGLEVIDEO, 'Google Video Ads'],
      ]);
      return titles.get(this.type) ?? '';
    },
    // TODO: move this logic to the BE
    // when this list contains of > 1000 items
    // this computed will provide weak experience by slow evaluation
    filteredUsers() {
      if (this.search.length < 3) return this.users;
      // check that searched string is in customer name
      // or that searched string is in name of ads
      return this.users.map((user: GoogleUser) => {
        const filteredCustomers = user.customers.map(c => {
          const searchStringInName = c.name.toLowerCase().includes(this.search.toLowerCase());
          const searchStringInAds = c.ads.filter(a => a.name.toLowerCase().includes(this.search.toLowerCase()));
          if (searchStringInName) {
            return c;
          }
          return {
            ...c,
            ads: searchStringInName || searchStringInAds.length ? searchStringInAds : [],
          };
        });
        return {
          ...user,
          customers: filteredCustomers,
        };
      });
    },
    selectedAdsGroupedByUser() {
      const ads = this.selectedAds;
      return this.users
        ?.map(user => {
          return {
            ...user,
            customers: user.customers
              .map(customer => {
                return {
                  ...customer,
                  ads: customer.ads.filter(ad =>
                    ads.some(selectedAd => selectedAd.adsId.includes(ad.id) && selectedAd.customerId === customer.id),
                  ),
                };
              })
              .filter(customer => customer.ads.length > 0),
          };
        })
        .filter(user => user.customers.length > 0);
    },
    enabled() {
      return this.users.length > 0;
    },
    connectedAds(): ConnectedAd[] {
      return this.selectedAdsGroupedByUser?.map((user: GoogleUser) => {
        const ads = user.customers.reduce(
          (all, customer) => [...all, ...customer.ads.map(ad => ({ adsId: ad.id, customerId: customer.id }))],
          [],
        );
        return {
          userId: user.id,
          ads,
        };
      });
    },
  },

  methods: {
    getConnectionDate(adId: string, customerId: string) {
      if (this.rawAds.length === 0) return '';
      else {
        const ad = this.rawAds.find(ad => ad.adsId === adId && ad.customerId === customerId);
        return ad ? `(${ad.connection_date})` : '';
      }
    },
    async getAvailableAds() {
      const users = await this.$store.dispatch('googleAds/getAvailableAds', this.advertiser.PropertyId);
      this.users = users;
    },
    async getConnectedAds() {
      const ads = await this.$store.dispatch('googleAds/getConnectedAds', {
        advertiserId: this.advertiser.PropertyId,
        type: this.type,
      });
      this.rawAds = ads;

      this.selectedAds = ads.reduce((acc, ad) => {
        const existingCustomer = acc.find(a => a.customerId === ad.customerId);
        if (existingCustomer) {
          existingCustomer.adsId.push(ad.adsId);
        } else {
          acc.push({
            customerId: ad.customerId,
            adsId: [ad.adsId],
          });
        }
        return acc;
      }, []);
    },
    async onSave() {
      this.updating = true;
      try {
        await this.$store.dispatch('googleAds/updateConnectedAds', {
          advertiserId: this.advertiser.PropertyId,
          type: this.type,
          connectedAds: this.connectedAds,
        });
        this.changed = false;
      } catch (error) {
        console.error(error);
        this.changed = true;
      } finally {
        this.updating = false;
      }
    },
    handleClick(ad, customerId) {
      const isSelected = this.handleIsSelected(ad, customerId);
      if (isSelected) {
        this.removeAd(ad.id, customerId);
      } else {
        this.addAd(ad.id, customerId);
      }
      this.changed = true;
    },
    addAd(adId, customerId) {
      const existingCustomer = this.selectedAds.find(ad => ad.customerId === customerId);
      if (existingCustomer) {
        if (!existingCustomer.adsId.includes(adId)) {
          existingCustomer.adsId.push(adId);
        }
      } else {
        this.selectedAds.push({ customerId: customerId, adsId: [adId] });
      }
    },
    removeAd(adId, customerId) {
      this.selectedAds = this.selectedAds.reduce((acc, ad) => {
        if (ad.customerId === customerId) {
          const filteredAdIds = ad.adsId.filter(id => id !== adId);
          if (filteredAdIds.length > 0) {
            acc.push({ ...ad, adsId: filteredAdIds });
          }
        } else {
          acc.push(ad);
        }
        return acc;
      }, []);
    },
    handleIsSelected(ad, customerId) {
      const customer = this.selectedAds.find(ad => ad.customerId === customerId);
      return customer ? customer.adsId.includes(ad.id) : false;
    },
  },
});

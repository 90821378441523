export interface StateType {
  error: string;
}
export interface RawGoogleAd {
  adsId: string;
  customerId: string;
  userId: string;
  connection_date: string;
}

export interface GoogleAd {
  id: number;
  name: string;
}

export interface GoogleCustomer {
  id: string;
  name: string;
  ads: GoogleAd[];
}

export interface GoogleUser {
  id: string;
  name: string;
  customers: GoogleCustomer[];
}

export enum AccountType {
  SEM = 'SEM',
  GOOGLEVIDEO = 'GOOGLEVIDEO',
}

export interface ConnectedAd {
  userId: string;
  ads: { adsId: string; customerId: string }[];
}
